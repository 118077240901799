import * as tslib_1 from "tslib";
import { UserService } from 'src/_services/user.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { AnalyticsService } from '@citadel/common-frontend/_services/analytics.service';
import { VersionService } from '../_services/version.service';
import { CampaignService } from 'src/_services/campaign.service';
import { AdService } from 'src/_services/ad.service';
import { NetworkService } from 'src/_services/network.service';
import { FilesystemService } from '@citadel/common-frontend/_services/filesystem.service';
import { BillingService } from 'src/_services/billing.service';
import { NetworkGroupService } from 'src/_services/network-group.service';
var loaded = false;
var AuthenticatedGuard = /** @class */ (function () {
    function AuthenticatedGuard(router, analytics, loader, socket, version, user, campaign, filesystem, billing, networkGroup, ad, network) {
        this.router = router;
        this.analytics = analytics;
        this.loader = loader;
        this.socket = socket;
        this.version = version;
        this.user = user;
        this.campaign = campaign;
        this.filesystem = filesystem;
        this.billing = billing;
        this.networkGroup = networkGroup;
        this.ad = ad;
        this.network = network;
    }
    AuthenticatedGuard.prototype.canActivate = function (route) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var completion, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (loaded) {
                            this.loader.setLoading(false);
                            resolve(true);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.socket.init(environment.api.url, null, environment.production).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.user.init(true).toPromise()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.analytics.init(environment.analytics.google.id).toPromise()];
                    case 4:
                        _a.sent();
                        completion = Promise.all([
                            this.version.init().toPromise(),
                            this.campaign.init().toPromise(),
                            this.ad.init().toPromise(),
                            this.network.init().toPromise(),
                            this.billing.init().toPromise(),
                            this.networkGroup.init().toPromise(),
                            this.filesystem.init(environment.api.url).toPromise()
                        ]);
                        completion.then(function () {
                            // hacky but necessary
                            var element = document.getElementById('cdm-preload');
                            if (element) {
                                element.remove();
                            }
                            _this.loader.setLoading(false);
                            loaded = true;
                            resolve(true);
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        // invalidate session
                        this.user.logout();
                        this.router.navigate(['/authentication']);
                        resolve(false);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); });
        return promise;
    };
    return AuthenticatedGuard;
}());
export { AuthenticatedGuard };
export function markForChange() {
    loaded = false;
}
